import React, {useRef, useState} from 'react';
import Mainlogobg from '../images/mainlogobg.jpg';

import emailjs from '@emailjs/browser';
import {
  EMAIL_JS_PUBLIC_KEY, EMAIL_JS_SERVICE_ID,
  EMAIL_JS_TEMPLATE_ID
} from "../../AppConstants";


function Contact() {
  const form = useRef();
  const [err, setErr] = useState({state: false, msg: "", alert: ""})
  const [isLoading, setIsLoading] = useState(false)

  function isEmail(email) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  }

  // const validateEmail = (email) => {
  //   return email.match(
  //     /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  //   );
  // };
  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const sendEmail = (e) => {
    e.preventDefault();


    if (validateEmail(e.target.email.value)) {
      setIsLoading(true)
      emailjs.sendForm(EMAIL_JS_SERVICE_ID, EMAIL_JS_TEMPLATE_ID, form.current,
        EMAIL_JS_PUBLIC_KEY)
        .then((result) => {
          console.log(result.text);
          setIsLoading(false)
          setErr({
            state: true,
            msg: "Email send we will get back to you soon",
            alert: "alert-success"
          })
          document.getElementById("contact-form").reset();
        }, (error) => {
          console.log(error.text);
          setIsLoading(false)
          setErr({
            state: true,
            msg: "Something went wrong",
            alert: "alert-danger"
          })
        });

    } else {
      setIsLoading(false)
      setErr({
        state: true,
        msg: "Invalid email",
        alert: "alert-danger"
      })

    }


  };


  return (

    <div className="container col-xxl-9 px-4 py-5 wrapper">
      <div class="row flex-lg-row-reverse align-items-center g-5 py-5 mt-5">


        <div class="col"></div>
        <div class="col-md-8">

          <div class="row mb-5">
            <div class="col"></div>
            <div class="col-md-8 text-center">
              <p className="textfooter-1">CONTACT US</p>
              <h1 className="heading-about">Get in Touch With Us</h1><br/>
              <h2 className="footerparagraph-2"><a
                href="../">HOME</a>&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;CONTACT</h2>
            </div>
            <div class="col"></div>
          </div>

          <section className="section-five"
                   style={{backgroundImage: `url(${Mainlogobg}`, backgroundRepeat: "no-repeat"}}>
            <form id="contact-form" ref={form} onSubmit={sendEmail}>
              <div class="row">

                <div className="col-md-12">
                  {err.state ?
                    <div className={`alert ${err.alert}`} role="alert">
                      {err.msg}
                    </div> : null
                  }
                </div>


                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control"
                           name="first" autocomplete="off" id="first" placeholder="First Name"/>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control"
                           name="second" autocomplete="off" required id="second" placeholder="Second Name"/>
                  </div>
                </div>
              </div>
              {/* first row form field */}


              <div class="row mt-4">
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control"
                           name="email" autocomplete="off" id="email" required placeholder="Email Address"/>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control"
                           name="phone" autocomplete="off" id="phone" required="" placeholder="Phone"/>
                  </div>
                </div>

              </div>
              {/* second row form field */}


              <div class="row mt-4">
                <div class="col-md-12">
                  <div class="form-group">
                    <textarea class="form-control textarea" rows="3" name="message" required id="message"
                              placeholder="Message..."></textarea>
                  </div>
                </div>
              </div>
              {/* text area form  */}


              <div class="row mt-5 mb-5">
                <div class="col"></div>
                <div class="col-md-10 text-center">
                  {
                    isLoading ? <div id="loading-spinner" className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div> :
                      <input type="submit" className="btn btn-primary" value="Send"/>
                  }
                </div>
                <div class="col"></div>
              </div>
              {/* send button */}
            </form>

          </section>
          <section id="map" className="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6779.706894995522!2d39.219083517560996!3d-6.771803636682422!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe86ba3211ffb0d96!2sIntelligenceFx!5e0!3m2!1sen!2stz!4v1667895695611!5m2!1sen!2stz"
              width="600"
              height="450"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />

          </section>


        </div>
        <div class="col"></div>


      </div>
    </div>

  );
}

export default Contact;