import  "../../css/Custom.css"

const MotionInview = ({children})=>{

    return(
        <div  className="animationContainer" >
            {children}
        </div>
    )

}

export default MotionInview;