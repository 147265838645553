import {useEffect, useState} from "react";

const UseOnScreen=(ref)=>
{

    const [isIntersecting, setIntersecting] = useState(false);

    const observer = new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    );

    useEffect(() => {
        try{
            observer.observe(ref.current);

        }catch (e) {
            console.log(e)
        }

        // Remove the observer as soon as the component is unmounted
        return () => { observer.disconnect() }
    }, []);

    return isIntersecting;
}

export default UseOnScreen;