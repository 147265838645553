import PropTypes from 'prop-types';
import {m} from 'framer-motion';

//
import Logo from '../../images/logo.png';

import "../../css/Custom.css"
// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

LoadingScreen.propTypes = {
  isDashboard: PropTypes.bool,
};

export default function LoadingScreen() {
  return (
    <>

      <div
        style={{
          right: 0,
          bottom: 0,
          zIndex: 99999,
          width: '100%',
          height: '100%',
          position: 'fixed',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'black',
        }}
      >

        <div class="circle-wrapper">
          <div class="success circle"></div>
          <div class="icon">
            <img src={Logo}>
            </img>
          </div>
        </div>


      </div>


    </>
  );
}
