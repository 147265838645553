import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {m, useAnimation, motion} from 'framer-motion';
import {useInView} from 'react-intersection-observer';


// ----------------------------------------------------------------------

MotionInViewCustom.propTypes = {
    children: PropTypes.node.isRequired,
    variants: PropTypes.object,
    transition: PropTypes.object,
    triggerOnce: PropTypes.bool,
    threshold: PropTypes.oneOfType([PropTypes.number, PropTypes.array])
};

export default function MotionInViewCustom({children, variants, ...others}) {


    return (
        <motion.div
            className="card-container"
            initial="initial"
            whileInView="animate"
            viewport={{once: true, amount: 0.8}}
        >
            <motion.div   {...others} variants={variants}>
                {children}
            </motion.div>
        </motion.div>
    );
}
