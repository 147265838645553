import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../images/logo.png';


function Navbar2() {
  return (


    <nav class="navbar navbar-dark fixed-top bg-darker navbar-expand-lg ">
      <div class="container">


        <a href="/" class="navbar-brand"><img src={Logo} width="80px" alt="Intelligence FX" /></a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">

            <li class="nav-item">
              <a href="/" class="nav-link hvr-underline-from-center pl-5 js-scroll-trigger" aria-current="page">Home</a>
            </li>

            <li class="nav-item">
              <a href="/about" class="nav-link hvr-underline-from-center" aria-current="page">About</a>
            </li>
            <li class="nav-item">
              <a href="/pricing" class="nav-link hvr-underline-from-center" aria-current="page">Pricing</a>
            </li>

            <li class="nav-item">
              <a href="/services" class="nav-link hvr-underline-from-center" aria-current="page">Services</a>
            </li>

            <li class="nav-item">
              <a href="/faqs" class="nav-link hvr-underline-from-center" aria-current="page">FAQs</a>
            </li>

            <li class="nav-item">
              <a href="http://intelligenceforex.com/blog_ifx" class="nav-link hvr-underline-from-center" aria-current="page">Blog</a>
            </li>

            <li class="nav-item">
              <a href="/appointment" class="nav-link hvr-underline-from-center" aria-current="page">Book Appointment</a>
            </li>


            <li class="nav-item">
              <a href="/contact" class="nav-link" aria-current="page">Contact</a>
            </li>

            <li class="nav-item">
              <a href="https://intelligenceforex.com/auth/login" class="btn btn-primary hvr-wobble-top">Member Login</a>
              {/* <button type="button" class="btn btn-primary hvr-wobble-top btn-lg px-4 me-md-2">Become a Member</button> */}
            </li>



          </ul>

        </div>
      </div>
    </nav>



  );
}

export default Navbar2;