import React from "react";
import UseScript from "../../../hoooks/UseScript";




const InstagramFeeds = ()=>{
  UseScript('https://apps.elfsight.com/p/platform.js');
  return (

    <div style={{background:'black',color:'white'}} >
      <div className="elfsight-app-6b6a53ad-6e57-4af0-9e75-43ba693076e0" />
    </div>
  )
}

export  default InstagramFeeds;