import React, {useRef} from 'react';
import Teammember1 from '../images/teammember1.png';
import Teammember2 from '../images/teammember2.png';
import Teammember3 from '../images/teammember3.png';
import Mainlogobg from '../images/mainlogobg.jpg';
import Teammember4 from '../images/Bertha.jpeg'
import Teammember5 from '../images/mareek.jpg'

import ceo from '../images/amri.jpeg'
import mike from '../images/mike.jpeg'

import UseOnScreen from "../../hoooks/UseOnScreeen";
import MotionInView from "../components/animate/MotionInView";
import TwitterFeeds from "../components/TwitterFeeds/TwitterFeeds";
import InstagramFeeds from "../components/InstagramFeeds/InstagramFeeds";
import MotionInViewCustom from "../components/animate/MotionInViewCustom";


function About() {


  const varFade = () => {
    return {
      inLeft: {
        animate: {x: 0, opacity: 1, transition: {duration: 0.64, ease: [0.43, 0.13, 0.23, 0.96]}},
        exit: {x: -120, opacity: 0, transition: {duration: 0.48, ease: [0.43, 0.13, 0.23, 0.96]}},
        initial: {x: 120, opacity: 0}
      },
      inLeftSlow: {
        animate: {x: 0, opacity: 1, transition: {duration: 1.94, ease: [0.43, 0.13, 0.23, 0.96]}},
        exit: {x: -120, opacity: 0, transition: {duration: 0.48, ease: [0.43, 0.13, 0.23, 0.96]}},
        initial: {x: 120, opacity: 0}
      },
      inUp: {
        initial: {y: 120, opacity: 0},
        animate: {y: 0, opacity: 1, transition: {duration: 0.94, ease: [0.43, 0.13, 0.23, 0.96]}},
        exit: {y: 120, opacity: 0, transition: {duration: 0.48, ease: [0.43, 0.13, 0.23, 0.96]}}


      },
      inUpSlow: {
        initial: {y: 120, opacity: 0},
        animate: {y: 0, opacity: 1, transition: {duration: 1.94, ease: [0.43, 0.13, 0.23, 0.96]}},
        exit: {y: 120, opacity: 0, transition: {duration: 0.48, ease: [0.43, 0.13, 0.23, 0.96]}}


      },
      inDown: {
        initial: {y: -120, opacity: 0},
        animate: {y: 0, opacity: 1, transition: {duration: 0.94, ease: [0.43, 0.13, 0.23, 0.96]}},
        exit: {y: -120, opacity: 0, transition: {duration: 0.48, ease: [0.43, 0.13, 0.23, 0.96]}}
      },
      inRight: {
        initial: {x: -120, opacity: 0},
        animate: {x: 0, opacity: 1, transition: {duration: 0.94, ease: [0.43, 0.13, 0.23, 0.96]}},
        exit: {x: -120, opacity: 0, transition: {duration: 0.48, ease: [0.43, 0.13, 0.23, 0.96]}}

      },
      upCustom: {
        initial: {
          y: 300,
          rotate: -10
        },
        animate: {
          y: 50,
          rotate: 0,
          transition: {
            type: "spring",
            bounce: 0.4,
            duration: 1.8
          }
        }
      },
      upCustom2: {
        initial: {
          y: 300,
          rotate: 10
        },
        animate: {
          y: 50,
          rotate: 0,
          transition: {
            type: "spring",
            bounce: 0.4,
            duration: 1.8
          }
        }
      }

    }
  }


  const div1 = useRef()
  const div2 = useRef()
  const isVisible1 = UseOnScreen(div1)
  const isVisible2 = UseOnScreen(div2)

  return (

    <div className="container col-xxl-9 px-4 py-5 wrapper">
      <div class="row flex-lg-row-reverse align-items-center g-5 py-5 mt-5">


        <div class="row mb-5">
          <div class="col"></div>
          <div class="col-md-8 text-center">
            <MotionInViewCustom
              variants={{
                animate: varFade().inDown.animate,
                initial: varFade().inDown.initial
              }}
              exit={varFade().inDown.exit}

            >
              <p className="textfooter-1">LEARN MORE</p>
              <h1 className="heading-about">About Us</h1><br/>
              <h2 className="footerparagraph-2"><a
                href="../">HOME</a>&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;ABOUT US</h2>
            </MotionInViewCustom>
          </div>
          <div class="col"></div>
        </div>


        <section className="section-one-about"
                 style={{backgroundImage: `url(${Mainlogobg}`, backgroundRepeat: "no-repeat"}}>

          <div class="row mb-5">
            <div class="col"></div>
            <div class="col-md-8">
              <MotionInViewCustom
                variants={{
                  animate: varFade().inLeftSlow.animate,
                  initial: varFade().inLeftSlow.initial
                }}
                exit={varFade().inLeftSlow.exit}

              >
              <h2 className="heading-2-about">INTELLIGENCEFX is a fast-growing firm which deals with financial market,
                based on foreign exchange trading, portfolio management, training and mentorship, programs that provide
                high-quality, in-depth knowledge of financial market in simplicity and significant way.
                <br/><br/>The founder of IntelligenceFx is Amrisali Mkwizu, who founded the firm in 2019 currently
                working with 10 members as a team.</h2>
              </MotionInViewCustom>
            </div>
            <div class="col"></div>
          </div>

        </section>


        <section className="section-seven-about">
          <div class="row ">
            <div class="col"></div>
            <div class="col-md-7 text-center mt-4 mb-5">
              <h1 class="heading-1 gsap-reveal-hero">Become an IntelligenceFX Member Today</h1>
              <p class="parag-2-about gsap-reveal-hero">IntelligenceFx has impacted more than 1000+ students</p>

              <div class="row">
                <div class="col-md-12 text-center">
                  <a href="" target="_blank" class="btn btn-primary hvr-wobble-top">Become a Member</a>
                </div>
              </div>
            </div>
            <div class="col"></div>
          </div>
        </section>
        {/*  review area link  */}


        <section className="section-ten">

          <div class="row mb-3">
            <div class="col"></div>
            <div class="col-md-8 text-center">
              <h1 class="shoulder-1">IntelligenceFX Team</h1>
            </div>
            <div class="col"></div>
          </div>

          <div class="row mt-5">
            <div className="col-md-1 mb-5 text-center">
            </div>

            <div className="col-md-2 mb-5 text-center">
              <div className=""><img src={Teammember5} className="img-fluid" alt="Coach" width=""/></div>
              <br/>
              <div ref={div1}>
                <MotionInView>
                  <div className={isVisible1 ? "customAnimation" : ""}>
                  </div>
                  <p className="testimony-3"><a href="https://instagram.com/mareek_berry?igshid=YmMyMTA2M2Y=" target="blank"><i
                    className="fa fa-instagram"></i></a>&nbsp;&nbsp;Mareek Paul,<br/>Founder &amp; Director</p>
                </MotionInView>
              </div>
            </div>

            <div class="col-md-2 mb-5 text-center">
              <div class=""><img src={ceo} class="img-fluid" alt="Coach" width=""/></div>
              <br/>
              <div ref={div1}>
                <MotionInView>
                  <div className={isVisible1 ? "customAnimation" : ""}>
                  </div>
                  <p class="testimony-3"><a href="https://www.instagram.com/amrisaly/" target="blank"><i
                    class="fa fa-instagram"></i></a>&nbsp;&nbsp;Amrisali Mkwizu,<br/>Founder &amp; CEO</p>
                </MotionInView>
              </div>
            </div>

            <div class="col-md-2 mb-5 text-center">
              <div class=""><img src={mike} class="img-fluid" alt="Coach " width=""/></div>
              <br/>
              <div ref={div1}>
                <MotionInView>
                  <div className={isVisible1 ? "customAnimation" : ""}>
                  </div>
                  <p class="testimony-3"><a href="https://www.instagram.com/adelinus_/" target="_blank"><i
                    class="fa fa-instagram"></i></a>&nbsp;&nbsp;Michael Dastan,<br/>Head Finance Department</p>
                </MotionInView>
              </div>
            </div>

            <div class="col-md-2 mb-5 text-center">
              <div class=""><img src={Teammember3} class="img-fluid" alt="Coach " width=""/></div>
              <br/>
              <div ref={div1}>
                <MotionInView>
                  <div className={isVisible1 ? "customAnimation" : ""}>
                  </div>
                  <p class="testimony-3"><a href="https://www.instagram.com/angelalbert12/" target="_blank"><i
                    class="fa fa-instagram"></i></a>&nbsp;&nbsp;Angel Majuva,<br/>
                    Head of Administration</p>
                </MotionInView>
              </div>
            </div>

            <div className="col-md-2 mb-5 text-center">
              <div className=""><img src={Teammember4} className="img-fluid" alt="Coach " width=""/></div>
              <br/>
              <div ref={div1}>
                <MotionInView>
                  <div className={isVisible1 ? "customAnimation" : ""}>
                  </div>
                  <p className="testimony-3">
                    <a href="https://instagram.com/berthaphilemon?igshid=YmMyMTA2M2Y=" target="_blank"><i
                    className="fa fa-instagram"></i></a>&nbsp;&nbsp;Bertha Philemon,<br/>
                   Community manager</p>
                </MotionInView>
              </div>
            </div>

          </div>


        </section>

        {/*start of instagram feeds*/}
        <section>
          <InstagramFeeds/>
        </section>
        {/*end of instagram feeds*/}

        {/*start of twitter feeds*/}
        <section>
          <div class="container">
            <div className="row mb-3">
              <div className="col"/>
              <div className="col-md-8 text-center">
                {/*<h1 className="socialMediaHeader">*/}
                {/*  Follow us on twitter*/}
                {/*</h1>*/}
              </div>
              <div className="col"/>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12 ">
                <div className="twitterFeedSideContainer">
                  <MotionInViewCustom
                    variants={{
                      animate: varFade().inDown.animate,
                      initial: varFade().inDown.initial
                    }}
                    exit={varFade().inDown.exit}

                  >

                    <h1 className="heading-1 gsap-reveal-hero">
                      FOLLOW US ON TWITTER
                    </h1>


                    {/*<h2 className="heading-2-about">*/}
                    {/*  IntelligenceFx team provides the best*/}
                    {/*  financial market knowledge to perfect your*/}
                    {/*  skills and make consistent profits in the*/}
                    {/*  financial market. .*/}

                    {/*</h2>*/}
                  </MotionInViewCustom>
                </div>

              </div>
              <div className="col-md-6 col-sm-12 twitterFeedContainer  ">
                <TwitterFeeds/>
              </div>
            </div>
          </div>


        </section>
        {/*end of twitter feeds*/}


      </div>
    </div>

  );
}

export default About;