import React from "react";
import 'font-awesome/css/font-awesome.min.css';


function Footer() {
  return (
    <section className="section-footer footer text-white">
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-3 mt-5 text-center">
            <h1 class="textfooter-1">SOCIAL MEDIA</h1>
            <div class="footer-social">
              <a href="https://instagram.com/intelligencefx?igshid=fplt6yvfw50z"><i className="fa fa-instagram"></i></a>
              <a href="https://t.me/intelligencefxfree"><i className="fa fa-telegram"></i></a>
              <a href="https://twitter.com/intelligencefx?s=11"><i className="fa fa-twitter"></i></a>
              <a href="https://www.tiktok.com/@intelligencefx?_t=8WgZTNg2WP7&_r=1">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                     fill="currentColor" className="bi bi-tiktok" viewBox="0 0 16 16">
                  <path
                    d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z"/>
                </svg>
              </a>
              <a href="https://youtube.com/channel/UC336Dz5WnRtTM1rEy28l_KQ"><i className="fa fa-youtube-play"></i></a>
              <a href="https://www.linkedin.com/company/ntelligencefx/"><i className="fa fa-linkedin-square"></i></a>
            </div>
          </div>

          <div class="col-md-3 mt-5">
            <h1 class="textfooter-1">CONTACT</h1>

            <p class="footerparagraph-1"><a href="tel:+255625554222"><i
              className="fa fa-phone"></i>&nbsp;&nbsp;&nbsp;+255 625 554 222</a></p>
            <p class="footerparagraph-1"><a href="mailto:info@intelligenceforex.com"><i
              className="fa fa-envelope"></i>&nbsp;&nbsp;&nbsp;info@intelligenceforex.com</a></p>
            <p class="footerparagraph-1"><a href="https://t.me/Intelligencefx"><i
              className="fa fa-telegram"></i>&nbsp;&nbsp;&nbsp;Telegram</a></p>
            <p class="footerparagraph-1"><a href="http://Wa.me/255625554222"><i
              className="fa fa-whatsapp"></i>&nbsp;&nbsp;&nbsp;Whatsapp</a></p>

          </div>


          <div class="col-md-2 mt-5">
            <h1 class="textfooter-1">QUICK LINKS</h1>

            <p class="footerparagraph-1"><a href="https://app.intelligenceforex.com/about"
                                            class="hvr-backward">ABOUT</a></p>
            <p class="footerparagraph-1"><a href="https://app.intelligenceforex.com/pricing"
                                            class="hvr-backward">PRICING</a></p>
            <p class="footerparagraph-1"><a href="https://app.intelligenceforex.com/services"
                                            class="hvr-backward">SERVICES</a></p>
            <p class="footerparagraph-1"><a href="https://app.intelligenceforex.com/blog_ifx"
                                            class="hvr-backward">BLOG</a></p>
            <p class="footerparagraph-1"><a href="https://app.intelligenceforex.com/faqs" class="hvr-backward">FAQ</a>
            </p>

          </div>

          <div class="col-md-4 mt-5">
            <h1 class="textfooter-1">RISK DISCLOSURE</h1>
            <p class="testimony-3">
              Foreign Exchange and CFD trading are high risk and not suitable for everyone. You should carefully
              consider your investment objectives, level of experience and risk appetite before making a decision to
              trade.
              Most importantly, do not invest money you cannot afford to lose</p>
          </div>

          {
            /* <div className="col-md-4">
                            <h6>Quick Links</h6>
                            <hr/>
                            <div><Link to="/">Home</Link></div>
                            <div><Link to="/about">About</Link></div>
                            <div><Link to="/">Home</Link></div>
                            <div><Link to="/">Home</Link></div>
                        </div> */
          }


        </div>
        <hr/>
        <div className="row">

          <div class="col"></div>
          <div class="col-md-6 text-center mt-3">
            <p class="testimony-3">&copy; 2023 IntelligenceFX. All Rights Reserved.</p>
          </div>
          <div class="col"></div>

        </div>


      </div>
    </section>
  );
}


export default Footer;