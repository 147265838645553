import React from "react";
import UseScript from "../../../hoooks/UseScript";


const TwitterFeeds = ()=>{
  UseScript('https://platform.twitter.com/widgets.js');
  return (

    <div style={{height:'90vh'}} >
      <a className="twitter-timeline"  data-theme="dark"
         href="https://twitter.com/intelligencefx?ref_src=twsrc%5Etfw">Tweets by intelligencefx</a>
    </div>
  )
}

export  default TwitterFeeds;