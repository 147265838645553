import './App.css';

import React from 'react';
import Navbar from './components/inc/Navbar';
import Footer from './components/inc/Footer';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Services from './components/pages/Services';
import Faqs from './components/pages/Faqs';
import Pricing from './components/pages/Pricing';
import Contact from './components/pages/Contact';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
// import PreLoader from './components/Preloader';
// import Lottie from "lottie-react";
import {useState, Suspense,lazy} from 'react';
import BackToTop from "react-back-to-top-button";
import arrow from "./logoarrow.png";
import LoadingScreen from "./components/components/LoadingScreen/LoadingScreen";
import CustomSuspense from "./components/components/CustomSuspense/CustomSuspense";
import Navbar2 from "./components/inc/Navbar2";



const Home1= lazy(()=>import('./components/pages/Home'))
const Home2= lazy(()=>import('./components/pages/Home2'))
const Services2= lazy(()=>import('./components/pages/Service2'))
const About1= lazy(()=>import('./components/pages/About'))
const Faq1= lazy(()=>import('./components/pages/Faqs'))
const Pricing1= lazy(()=>import('./components/pages/Pricing'))
const Services1= lazy(()=>import('./components/pages/Services'))
const Contact1= lazy(()=>import('./components/pages/Contact'))
const Appointment1= lazy(()=>import('./components/pages/Appointment'))
const TradeClub= lazy(()=>import('./components/pages/Tradeclub'))
const Home_new= lazy(()=>import('./components/pages/Home_new'))
const Pricing_new= lazy(()=>import('./components/pages/Pricing_new'))
const EmailVerification= lazy(()=>import('./components/pages/EmailVerification'))


function App() {
  return (
    <div>
      <Router>
        <section className="App">
          <div id="fh5co-wrap">
            <Navbar/>
              <Routes>
                <Route path="/" element={ <CustomSuspense><Home_new/></CustomSuspense> }/>
                <Route path="/home2" element={ <CustomSuspense><Home/></CustomSuspense> }/>
                {/*<Route path="/services2" element={ <CustomSuspense><Services2/></CustomSuspense> }/>*/}
                <Route path="/about" element={ <CustomSuspense> <About1/></CustomSuspense>}/>
                <Route path="/services" element={<CustomSuspense> <Services2/></CustomSuspense>}/>
                <Route path="/faqs" element={<CustomSuspense>  <Faq1/> </CustomSuspense>}/>
                <Route path="/pricing" element={ <CustomSuspense> <Pricing_new/></CustomSuspense>}/>
                <Route path="/contact" element={ <CustomSuspense> <Contact1/> </CustomSuspense> }/>
                <Route path="/appointment" element={ <CustomSuspense> <Appointment1/> </CustomSuspense> }/>
                <Route path="/tradeclub" element={ <CustomSuspense> <TradeClub/> </CustomSuspense> }/>
                <Route path="/pricing-new" element={ <CustomSuspense> <Pricing_new/> </CustomSuspense> }/>
                <Route path="/home-new" element={ <CustomSuspense> <Home_new/> </CustomSuspense> }/>
                <Route path="/email-verification/:id" element={ <CustomSuspense> <EmailVerification/> </CustomSuspense> }/>
                <Route path="/email-verification" element={ <CustomSuspense> <EmailVerification/> </CustomSuspense> }/>

              </Routes>

          </div>
          <Footer/>
        </section>
      </Router>
      <BackToTop
        showOnScrollDown
        showAt={50}
        speed={3000}
        easing="easeInOutQuint">
        <div className="">
          <img alt="arrow_up" className="arrow" src={arrow}></img>
        </div>
      </BackToTop>
    </div>
    // )  

  );
}

export default App;


