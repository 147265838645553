import React from 'react';
import Tick from '../images/tick.png';
import Mainlogobg from '../images/mainlogobg.jpg';

function Pricing() {
  return (

    <div className="container col-xxl-9 px-4 py-5 wrapper">
      <div class="row flex-lg-row-reverse align-items-center g-5 py-5 mt-5">


        <div class="row mb-5">
          <div class="col"></div>
          <div class="col-md-8 text-center">
            <p className="textfooter-1">LEARN MORE</p>
            <h1 className="heading-about">Membership Plan</h1><br/>
            <h2 className="footerparagraph-2"><a
              href="../">HOME</a>&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;PRICING</h2>
          </div>
          <div class="col"></div>
        </div>


        <section className="section-pricing"
                 style={{backgroundImage: `url(${Mainlogobg}`, backgroundRepeat: "no-repeat"}}>
          <div class="row mt-5">
            {/* <div class="col"></div> */}

            <div class="col-md-4 mb-5">
              <div class="card-price text-center">
                <div class="card-header text-center">
                  TRAINING AND MENTORSHIP
                </div>
                <br/>
                <h1 className="heading-pricing">$750</h1>
                <p className="heading-pricing-2">Online</p>
                <div class="card-body">
                  <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                    class="img-fluid"/>&nbsp;&nbsp;&nbsp;One to one session (via zoom)
                  </p>
                  <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                    class="img-fluid"/>&nbsp;&nbsp;&nbsp;Access to IntelligenceFx course
                  </p>
                  <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                    class="img-fluid"/>&nbsp;&nbsp;&nbsp;Traders strategy Guidebook</p>
                  <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                    class="img-fluid"/>&nbsp;&nbsp;&nbsp;Mid Week Market
                    Breakdown &amp; Review</p>
                  <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                    class="img-fluid"/>&nbsp;&nbsp;&nbsp;Weekend Market Breakdown</p>
                  <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                    class="img-fluid"/>&nbsp;&nbsp;&nbsp;Lifetime access to community
                  </p>
                  <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                    class="img-fluid"/>&nbsp;&nbsp;&nbsp;Access to student dashboard</p>
                  <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                    class="img-fluid"/>&nbsp;&nbsp;&nbsp;Lifetime mentorship</p>
                  <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                    class="img-fluid"/>&nbsp;&nbsp;&nbsp;Access to all previous recorded
                    material</p>
                  <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                    class="img-fluid"/>&nbsp;&nbsp;&nbsp;Access to all coming
                    content/material</p>
                  <hr/>
                  <a href="https://intelligenceforex.com/auth/register?package=7fb2863d861142758bb053d43b68e46c"
                     class="btn btn-primary hvr-wobble-top">Become A Member</a>
                </div>
              </div>
            </div>

            <div class="col-md-4 mb-5">
              <div class="card-price text-center">
                <div class="card-header text-center">
                  TRAINING AND MENTORSHIP
                </div>
                <br/>
                <h1 className="heading-pricing">$1,500</h1>
                <p className="heading-pricing-2">Physical</p>

                <div class="card-body">
                  <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                    class="img-fluid"/>&nbsp;&nbsp;&nbsp;One to one session (physical)
                  </p>
                  <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                    class="img-fluid"/>&nbsp;&nbsp;&nbsp;Access to IntelligenceFx course
                  </p>
                  <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                    class="img-fluid"/>&nbsp;&nbsp;&nbsp;Traders strategy Guidebook</p>
                  <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                    class="img-fluid"/>&nbsp;&nbsp;&nbsp;Mid Week Market
                    Breakdown &amp; Review</p>
                  <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                    class="img-fluid"/>&nbsp;&nbsp;&nbsp;Weekend Market Breakdown</p>

                  <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                    class="img-fluid"/>&nbsp;&nbsp;&nbsp;IntelligenceFx meet up </p>
                  <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                    class="img-fluid"/>&nbsp;&nbsp;&nbsp;lifetime access to community
                  </p>
                  <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                    class="img-fluid"/>&nbsp;&nbsp;&nbsp;access to student dashboard</p>
                  <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                    class="img-fluid"/>&nbsp;&nbsp;&nbsp;lifetime mentorship</p>
                  <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                    class="img-fluid"/>&nbsp;&nbsp;&nbsp;access to all previous recorded
                    material </p>
                  <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                    class="img-fluid"/>&nbsp;&nbsp;&nbsp;Access to all coming
                    content/material </p>
                  <hr/>
                  <a href="https://intelligenceforex.com/auth/register?package=2dd35de0d9d74d2f9e30f0abd2db235b"
                     class="btn btn-primary hvr-wobble-top">Become A Member</a>
                </div>

              </div>
            </div>


            <div class="col-md-4 mb-5">
              <div class="card-price-mid prices text-center">
                <div class="card-header box text-center">
                  <span class="advanced">Coming Soon</span>
                  BOOTCAMP AND MENTORSHIP
                </div>
                <br/>
                <h1 className="heading-pricing"></h1>
                <p className="heading-pricing-2">There will be only one Bootcamp and limited seats in 2023,
                  for maximum effectiveness.</p>
                <a href="#" class="btn btn-primary  hvr-wobble-top">Become A Member</a>

                <div class="card-body">
                  <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                    class="img-fluid"/>&nbsp;&nbsp;&nbsp;Advanced trading psychology
                    sessions</p>
                  <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                    class="img-fluid"/>&nbsp;&nbsp;&nbsp;9 Days IFX Zoom Class</p>
                  <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                    class="img-fluid"/>&nbsp;&nbsp;&nbsp;Questions and Answers Session
                  </p>
                  <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                    class="img-fluid"/>&nbsp;&nbsp;&nbsp;Recorded Bootcamp Series</p>
                  <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                    class="img-fluid"/>&nbsp;&nbsp;&nbsp;Lifetime Access Community</p>

                  <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                    class="img-fluid"/>&nbsp;&nbsp;&nbsp;Access to student Dashboard
                  </p>
                  <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                    class="img-fluid"/>&nbsp;&nbsp;&nbsp;lifetime mentorship</p>
                  <hr/>
                </div>

              </div>
            </div>

            {/* <div class="col"></div> */}
          </div>

        </section>
        { /* membership package */}


        <section className="section-pricing"
                 style={{backgroundImage: `url(${Mainlogobg}`, backgroundRepeat: "no-repeat"}}>
          <div class="row mt-5">

            <div class="row mt-5">
              <div class="col-md-12 homeonly">
                <h2>Signal &amp; Setups</h2>
              </div>
            </div>


            <div class="row mt-5">

              <div class="col-md-3 mb-5">
                <div class="card-price text-center">
                  <div class="card-header text-center">
                    SIGNALS &amp; SETUPS
                  </div>
                  <br/>
                  <h1 className="heading-pricing">$100</h1>
                  <p className="heading-pricing-2">FOR ONE MONTH</p>

                  <div class="card-body">
                    <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                      class="img-fluid"/>&nbsp;&nbsp;&nbsp;Trading signal and setup</p>
                    <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                      class="img-fluid"/>&nbsp;&nbsp;&nbsp;Weekly market outlook</p>
                    <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                      class="img-fluid"/>&nbsp;&nbsp;&nbsp;Midweek market outlook</p>
                    <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                      class="img-fluid"/>&nbsp;&nbsp;&nbsp;Trades updates</p>
                    <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                      class="img-fluid"/>&nbsp;&nbsp;&nbsp;Monthly live webinar</p>
                    <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                      class="img-fluid"/>&nbsp;&nbsp;&nbsp;Trading plan and planning
                      trades</p>
                    <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                      class="img-fluid"/>&nbsp;&nbsp;&nbsp;Economic calendar alert</p>
                    <hr/>
                    <a href="https://intelligenceforex.com/auth/register?package=c0a78e8257344a518e310370aa78de73"
                       class="btn btn-primary hvr-wobble-top">Start Now</a>
                  </div>

                </div>
              </div>
              {/* section for signal only */}

              <div class="col-md-3 mb-5">
                <div class="card-price text-center">
                  <div class="card-header text-center">
                    SIGNALS &amp; SETUPS
                  </div>
                  <br/>
                  <h1 className="heading-pricing">$250</h1>
                  <p className="heading-pricing-2">FOR THREE MONTHs</p>

                  <div class="card-body">
                    <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                      class="img-fluid"/>&nbsp;&nbsp;&nbsp;Trading signal and setup</p>
                    <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                      class="img-fluid"/>&nbsp;&nbsp;&nbsp;Weekly market outlook</p>
                    <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                      class="img-fluid"/>&nbsp;&nbsp;&nbsp;Midweek market outlook</p>
                    <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                      class="img-fluid"/>&nbsp;&nbsp;&nbsp;Trades updates</p>
                    <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                      class="img-fluid"/>&nbsp;&nbsp;&nbsp;Monthly live webinar</p>
                    <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                      class="img-fluid"/>&nbsp;&nbsp;&nbsp;Trading plan and planning
                      trades</p>
                    <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                      class="img-fluid"/>&nbsp;&nbsp;&nbsp;Economic calendar alert</p>
                    <hr/>
                    <a href="https://intelligenceforex.com/auth/register?package=412084d29daf47ebb61692584a622c4c"
                       class="btn btn-primary hvr-wobble-top">Start Now</a>
                  </div>

                </div>
              </div>
              {/* 2 section for signal only */}

              <div class="col-md-3 mb-5">
                <div class="card-price text-center">
                  <div class="card-header text-center">
                    SIGNALS &amp; SETUPS
                  </div>
                  <br/>
                  <h1 className="heading-pricing">$420</h1>
                  <p className="heading-pricing-2">FOR ONE YEAR</p>

                  <div class="card-body">
                    <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                      class="img-fluid"/>&nbsp;&nbsp;&nbsp;Trading signal and setup</p>
                    <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                      class="img-fluid"/>&nbsp;&nbsp;&nbsp;Weekly market outlook</p>
                    <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                      class="img-fluid"/>&nbsp;&nbsp;&nbsp;Midweek market outlook</p>
                    <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                      class="img-fluid"/>&nbsp;&nbsp;&nbsp;Trades updates</p>
                    <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                      class="img-fluid"/>&nbsp;&nbsp;&nbsp;Monthly live webinar</p>
                    <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                      class="img-fluid"/>&nbsp;&nbsp;&nbsp;Trading plan and planning
                      trades</p>
                    <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                      class="img-fluid"/>&nbsp;&nbsp;&nbsp;Economic calendar alert</p>
                    <hr/>
                    <a href="https://intelligenceforex.com/auth/register?package=8e04bd0ded85467687d6e8b12cb53e69"
                       class="btn btn-primary hvr-wobble-top">Start Now</a>
                  </div>

                </div>
              </div>
              {/* 3 section for signal only */}

              <div class="col-md-3 mb-5">
                <div class="card-price text-center">
                  <div class="card-header text-center">
                    SIGNALS &amp; SETUPS
                  </div>
                  <br/>
                  <h1 className="heading-pricing">$840</h1>
                  <p className="heading-pricing-2">LIFETIME PACKAGE</p>

                  <div class="card-body">
                    <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                      class="img-fluid"/>&nbsp;&nbsp;&nbsp;Trading signal and setup</p>
                    <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                      class="img-fluid"/>&nbsp;&nbsp;&nbsp;Weekly market outlook</p>
                    <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                      class="img-fluid"/>&nbsp;&nbsp;&nbsp;Midweek market outlook</p>
                    <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                      class="img-fluid"/>&nbsp;&nbsp;&nbsp;Trades updates</p>
                    <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                      class="img-fluid"/>&nbsp;&nbsp;&nbsp;Monthly live webinar</p>
                    <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                      class="img-fluid"/>&nbsp;&nbsp;&nbsp;Trading plan and planning
                      trades</p>
                    <p className="parag-pricing"><img src={Tick} width="14px" alt="tick"
                                                      class="img-fluid"/>&nbsp;&nbsp;&nbsp;Economic calendar alert</p>
                    <hr/>
                    <a href="https://intelligenceforex.com/auth/register?package=b3e8da3e9c0d400fa237de95d74ce802"
                       class="btn btn-primary hvr-wobble-top">Start Now</a>
                  </div>

                </div>
              </div>
              {/* 4 section for signal only */}


            </div>


          </div>
        </section>
        {/* Signals Setup Section */}


      </div>
    </div>

  );
}

export default Pricing;