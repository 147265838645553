import React, {useRef} from 'react';
import Circle from '../images/circle.png';
import Service1 from '../images/service1.png';
import Service2 from '../images/service2.png';
import Service3 from '../images/service3.png';

import Photo1 from '../images/setups/photo1.png';
import Photo2 from '../images/setups/photo2.png';
import Photo3 from '../images/setups/photo3.png';

import Mainlogobg from '../images/mainlogobg.jpg';
import MotionInview from "../components/animate/MotionInView";
import UseOnScreen from "../../hoooks/UseOnScreeen";

import "../css/Custom.css"
import MotionInViewCustom from "../components/animate/MotionInViewCustom";

function Services() {
  const div1 = useRef()
  const div2 = useRef()
  const div3 = useRef()
  const isVisible1 = UseOnScreen(div1)
  const isVisible2 = UseOnScreen(div2)
  const isVisible3 = UseOnScreen(div3)
  const varFade = () => {
    return {
      inLeft: {
        animate: {x: 0, opacity: 1, transition: {duration: 0.64, ease: [0.43, 0.13, 0.23, 0.96]}},
        exit: {x: -120, opacity: 0, transition: {duration: 0.48, ease: [0.43, 0.13, 0.23, 0.96]}},
        initial: {x: 120, opacity: 0}
      },
      inLeftSlow: {
        animate: {x: 0, opacity: 1, transition: {duration: 1.94, ease: [0.43, 0.13, 0.23, 0.96]}},
        exit: {x: -120, opacity: 0, transition: {duration: 0.48, ease: [0.43, 0.13, 0.23, 0.96]}},
        initial: {x: 120, opacity: 0}
      },
      inUp: {
        initial: {y: 120, opacity: 0},
        animate: {y: 0, opacity: 1, transition: {duration: 0.94, ease: [0.43, 0.13, 0.23, 0.96]}},
        exit: {y: 120, opacity: 0, transition: {duration: 0.48, ease: [0.43, 0.13, 0.23, 0.96]}}


      },
      inUpSlow: {
        initial: {y: 120, opacity: 0},
        animate: {y: 0, opacity: 1, transition: {duration: 1.94, ease: [0.43, 0.13, 0.23, 0.96]}},
        exit: {y: 120, opacity: 0, transition: {duration: 0.48, ease: [0.43, 0.13, 0.23, 0.96]}}


      },
      inDown: {
        initial: {y: -120, opacity: 0},
        animate: {y: 0, opacity: 1, transition: {duration: 0.94, ease: [0.43, 0.13, 0.23, 0.96]}},
        exit: {y: -120, opacity: 0, transition: {duration: 0.48, ease: [0.43, 0.13, 0.23, 0.96]}}
      },
      inRight: {
        initial: {x: -120, opacity: 0},
        animate: {x: 0, opacity: 1, transition: {duration: 0.94, ease: [0.43, 0.13, 0.23, 0.96]}},
        exit: {x: -120, opacity: 0, transition: {duration: 0.48, ease: [0.43, 0.13, 0.23, 0.96]}}

      },
      upCustom: {
        initial: {
          y: 300,
          rotate: -10
        },
        animate: {
          y: 50,
          rotate: 0,
          transition: {
            type: "spring",
            bounce: 0.4,
            duration: 1.8
          }
        }
      },
      upCustom2: {
        initial: {
          y: 300,
          rotate: 10
        },
        animate: {
          y: 50,
          rotate: 0,
          transition: {
            type: "spring",
            bounce: 0.4,
            duration: 1.8
          }
        }
      }

    }
  }

  return (


    <div className="container col-xxl-9 px-4 py-5 wrapper">
      <div class="row flex-lg-row-reverse align-items-center g-5 py-5 mt-5">


        <div class="row mb-5">
          <div class="col"></div>
          <div class="col-md-8 text-center">
            <MotionInViewCustom
              variants={{
                animate: varFade().inDown.animate,
                initial: varFade().inDown.initial
              }}
              exit={varFade().inDown.exit}
            >
              <h1 className="heading-about">Our Services</h1>

              <br/>
              <h2 className="footerparagraph-2"><a
                href="../">HOME</a>&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;SERVICES</h2>
            </MotionInViewCustom>
          </div>
          <div class="col"></div>
        </div>


        <section className="section-service">


          <div className="container">
            <div className="row">

              <div class="col-md-10 text-center">

                <div class="row">
                  <div class="col-md-3"></div>
                  <div class="col-md-2 topline-1"></div>
                  <div class="col"></div>
                  <div class="col-md-6">
                    <MotionInViewCustom
                      variants={{
                        animate: varFade().inLeft.animate,
                        initial: varFade().inLeft.initial
                      }}
                      exit={varFade().inLeft.exit}
                    >
                      <h2 className="heading-1-service ">TRAINING &amp; MENTORSHIP</h2>
                    </MotionInViewCustom>
                    <MotionInViewCustom
                      variants={{
                        animate: varFade().inLeftSlow.animate,
                        initial: varFade().inLeftSlow.initial
                      }}
                      exit={varFade().inLeftSlow.exit}
                    >
                      <p class="heading-2-service">We transfer right skills, provide sensitive market information,
                        one to one support to help you to become consistency and profitable trader.</p>
                    </MotionInViewCustom>
                  </div>
                  <div class="col"></div>

                </div>

                <div class="row">
                  <div class="col-md-3"></div>
                  <div class="col-md-4">
                    <div id="bg_image" class="img-fluid"><img src={Circle} alt="Intelligence FX"/></div>
                  </div>
                  <div class="col"></div>
                </div>

              </div>
            </div>
          </div>
        </section>
        {/*  intro section  */}


        <div class="row mt-5 mb-5" style={{backgroundImage: `url(${Mainlogobg}`, backgroundRepeat: "no-repeat"}}>
          <div class="col"></div>
          <div class="col-md-4">
            <div class=""><img src={Service1} class="img-fluid" alt="Signal" width=""/></div>
          </div>
          <div class="col-md-6 mt-5 " ref={div1}>
            <MotionInview>
              <div className={isVisible1 ? "customAnimation" : ""}>
              </div>

              <h1 class="head-1-service ">ONE-TO-ONE TRAINING</h1>
              <h2 class="heading-2-service">There are many limiting beliefs that can influence your trading.
                If you are able to recognize these beliefs and then address thme, you will be able to overcome
                them.</h2>
            </MotionInview>
            <div class="light-line-small"></div>

          </div>
          <div class="col"></div>
        </div>
        { /* service 1 */}


        <section className="section-seven">
          <div class="row ">

            <div class="col"></div>
            <div class="col-md-6 mt-5 unique" ref={div2}>
              <MotionInview>
                <div className={isVisible2 ? "customAnimation" : ""}>
                </div>
                <h1 class="head-1-service  gsap-reveal-hero">ACCESS TO MATERIAL</h1>
                <h2 class="heading-2-service gsap-reveal-hero">Cover everything you need to know on how to get started
                  and
                  developing your career in this highly exciting invetsment field.</h2>
              </MotionInview>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-4 mt-5 mb-5">
              <div class=""><img src={Service2} class="img-fluid" alt="Signal" width=""/></div>
            </div>
            <div class="col"></div>

          </div>
        </section>
        {/*  service 2 grey area  */}


        <div class="row mt-5 mb-5 unique-50"
             style={{backgroundImage: `url(${Mainlogobg}`, backgroundRepeat: "no-repeat"}}>
          <div class="col"></div>
          <div class="col-md-4">
            <div class=""><img src={Service3} class="img-fluid" alt="Signal" width=""/></div>
          </div>
          <div class="col-md-6 mt-5 unique-2" ref={div3}>
            <MotionInview>
              <div className={isVisible3 ? "customAnimation" : ""}>
              </div>
              <h1 class="head-1-service ">ACCESS TO COMMUNITY GROUP</h1>
              <h2 class="heading-2-service">One team one goal, Hosting a webinar and workshop going through all of our
                trades,
                setups and questions that will help to perform and implement practical trading strategies.</h2>
            </MotionInview>
            <div class="light-line-small"></div>

          </div>
          <div class="col"></div>
        </div>
        { /* service 3 */}


        <section className="section-four">
          <div class="container-fluid">
            <div className="row ">

              <div class="col-md-5 mb-5 unique-100">
                <div class="about-us text-center">
                  <h1 className="heading-1">SIGNALS &amp; SETUPS</h1>
                  <p className="head-2">We provide signal and setup with high accuracy, sniper entries and trades with
                    good risk to reward ratio.</p>
                  <a class="btn btn-primary hvr-shadow" href="https://t.me/Intelligencefx">Subscribe</a>
                </div>
              </div>


              <div class="col-md-7">


                <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
                  <div class="carousel-inner">
                    <div class="carousel-item active" data-bs-interval="10000">
                      <img src={Photo1} class="img-fluid" alt="First slide"/>
                    </div>
                    <div class="carousel-item" data-bs-interval="2000">
                      <img src={Photo2} class="img-fluid" alt="First slide"/>
                    </div>
                    <div class="carousel-item">
                      <img src={Photo3} class="img-fluid" alt="First slide"/>
                    </div>
                  </div>
                  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval"
                          data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval"
                          data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>


              </div>


            </div>
          </div>
        </section>
        {/* signal section */}


      </div>
    </div>


  );
}

export default Services;