import React, {useRef} from 'react';
import {m, motion} from 'framer-motion';
import Billboard from '../images/billboard.png';
import Circle from '../images/circle.png';
import Series from '../images/series.png';
import Private from '../images/private.png';
import Trainingone from '../images/trainingone.png';
import Trainingtwo from '../images/trainingtwo.png';
import Trainingthree from '../images/trainingthree.png';
import  Trainingfour  from '../images/trainingfour.jpeg';

import Signal from '../images/signal.png';
import Testimonialamri from '../images/testimonialamri.png';
import Testimonialangel from '../images/testimonialangel.png';
import Testimonialdastan from '../images/testimonialdastan.png';
import Testimonial1 from '../images/testimonial1.png';
import Testimonial2 from '../images/testimonial2.png';
import Testimonial3 from '../images/testimonial3.png';
import Coach from '../images/coach.png';
import Mainlogobg from '../images/mainlogobg.jpg';
import MotionInView from "../components/animate/MotionInView";
import MotionInViewCustom from "../components/animate/MotionInViewCustom";
import UseOnScreen from "../../hoooks/UseOnScreeen";

import "../css/Custom.css"


const varFade = () => {
  return {
    inLeft: {
      animate: {x: 0, opacity: 1, transition: {duration: 0.64, ease: [0.43, 0.13, 0.23, 0.96]}},
      exit: {x: -120, opacity: 0, transition: {duration: 0.48, ease: [0.43, 0.13, 0.23, 0.96]}},
      initial: {x: 120, opacity: 0}
    },
    inLeftSlow: {
      animate: {x: 0, opacity: 1, transition: {duration: 1.94, ease: [0.43, 0.13, 0.23, 0.96]}},
      exit: {x: -120, opacity: 0, transition: {duration: 0.48, ease: [0.43, 0.13, 0.23, 0.96]}},
      initial: {x: 120, opacity: 0}
    },
    inUp: {
      initial: {y: 120, opacity: 0},
      animate: {y: 0, opacity: 1, transition: {duration: 0.94, ease: [0.43, 0.13, 0.23, 0.96]}},
      exit: {y: 120, opacity: 0, transition: {duration: 0.48, ease: [0.43, 0.13, 0.23, 0.96]}}


    },
    inUpSlow: {
      initial: {y: 120, opacity: 0},
      animate: {y: 0, opacity: 1, transition: {duration: 1.94, ease: [0.43, 0.13, 0.23, 0.96]}},
      exit: {y: 120, opacity: 0, transition: {duration: 0.48, ease: [0.43, 0.13, 0.23, 0.96]}}


    },
    inDown: {
      initial: {y: -120, opacity: 0},
      animate: {y: 0, opacity: 1, transition: {duration: 0.94, ease: [0.43, 0.13, 0.23, 0.96]}},
      exit: {y: -120, opacity: 0, transition: {duration: 0.48, ease: [0.43, 0.13, 0.23, 0.96]}}
    },
    inRight: {
      initial: {x: -120, opacity: 0},
      animate: {x: 0, opacity: 1, transition: {duration: 0.94, ease: [0.43, 0.13, 0.23, 0.96]}},
      exit: {x: -120, opacity: 0, transition: {duration: 0.48, ease: [0.43, 0.13, 0.23, 0.96]}}

    },
    upCustom: {
      initial: {
        y: 300,
        rotate: -10
      },
      animate: {
        y: 50,
        rotate: 0,
        transition: {
          type: "spring",
          bounce: 0.4,
          duration: 1.8
        }
      }
    },
    upCustom2: {
      initial: {
        y: 300,
        rotate: 10
      },
      animate: {
        y: 50,
        rotate: 0,
        transition: {
          type: "spring",
          bounce: 0.4,
          duration: 1.8
        }
      }
    }

  }
}


function Home() {

  const div1 = useRef()
  const div2 = useRef()
  const isVisible1 = UseOnScreen(div1)
  const isVisible2 = UseOnScreen(div2)


  return (


    <div className="container col-xxl-9 px-4 py-5 wrapper">

      <div class="row unique-100 flex-lg-row-reverse align-items-center g-5 py-5 mt-5">

        <div class="col-lg-6 unique-50">


          <motion.div

            animate={{x: 0, opacity: 1, transition: {duration: 0.64, ease: [0.43, 0.13, 0.23, 0.96]}}}
            exit={{x: -120, opacity: 0, transition: {duration: 0.48, ease: [0.43, 0.13, 0.23, 0.96]}}}
            initial={{x: 120, opacity: 0}}
          >

            <h1 class="hero-1 fw-bold gsap-reveal-hero">WE TAKE YOUR TRADING</h1>
            <h1 class="hero-5 fw-bold text-secondary">TO THE NEXT LEVEL </h1>

          </motion.div>
          <motion.div
            animate={{x: 0, opacity: 1, transition: {duration: 0.94, ease: [0.43, 0.13, 0.23, 0.96]}}}
            exit={{x: -120, opacity: 0, transition: {duration: 0.48, ease: [0.43, 0.13, 0.23, 0.96]}}}
            initial={{x: 120, opacity: 0}}
          >
            <p className="lead-5">IntelligenceFX is a Financial Trading Firm that helps aspiring Traders in
              their journey of the Financial Market to become Profitable.</p>
            <div class="d-grid gap-2 d-md-flex justify-content-md-start">
              <button type="button"
                      class="btn btn-primary  hvr-wobble-top btn-lg px-4 me-md-2 mt-3">Become a Member
              </button>
              {/* <button type="button" class="btn btn-outline-secondary btn-lg px-4">Default</button> */}
            </div>
          </motion.div>
        </div>

        <div class="col-10 unique-50 col-sm-8 col-lg-6">
          <motion.div
            initial={{y: 120, opacity: 0}}
            animate={{y: 0, opacity: 1, transition: {duration: 0.64, ease: [0.43, 0.13, 0.23, 0.96]}}}
            exit={{y: 120, opacity: 0, transition: {duration: 0.48, ease: [0.43, 0.13, 0.23, 0.96]}}}
          >
            <img src={Billboard} class="mx-lg-auto img-fluid" alt="Bootstrap" loading="lazy"/>
          </motion.div>
        </div>
      </div>
      <div className="container" >
        <div className="col-md-12">

          <a href="#about" className="mouse-wrap smoothscroll">
            <span className="mouse">
              <span className="scroll"></span>
              <span className="pulse"></span>
            </span>
            <span className="mouse-label">Scroll</span>
          </a>


        </div>
      </div>

      <section id="about"  className="section">
        <div className="container">
          <div className="row">

            <div class="col-md-10 ">
              <div class="row">
                <div class="col"></div>
                <div class="col-md-2 topline-1"></div>
                <div class="col"></div>
                <div class="col-md-6">
                  <MotionInViewCustom
                    variants={{
                      animate: {
                        y: 0,
                        opacity: 1,
                        transition: {duration: 0.64, ease: [0.43, 0.13, 0.23, 0.96]}
                      },
                      initial: {
                        y: 120, opacity: 0
                      }
                    }}
                    exit={{
                      y: 120,
                      opacity: 0,
                      transition: {duration: 0.48, ease: [0.43, 0.13, 0.23, 0.96]}
                    }}

                  >

                    <h2 className="heading-1 ">IntelligenceFX</h2>
                    <p class="heading-2">provides the best financial market knowledge to perfect
                      your
                      skills and make consistent profits in the financial market.</p>
                  </MotionInViewCustom>


                </div>
                <div class="col"></div>

              </div>

              <div class="row">
                <div class="col-md-3">

                </div>
                <div class="col-md-4">
                  <div id="bg_image" class="img-fluid">
                    <img src={Circle} alt="Intelligence FX"/>
                  </div>
                </div>
                <div class="col">

                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      {/*  intro section  */}


      <section className="section-four">
        <div class="container-fluid">

          <div class="row">

            <div class="col-lg-6">
              <MotionInViewCustom
                variants={{
                  animate: varFade().inDown.animate,
                  initial: varFade().inDown.initial
                }}
                exit={varFade().inDown.exit}
              >

                <div class="row mt-5">

                  <div class="col-md-3">
                    <img src={Series} width="80px" class="img-fluid" alt="private community"/>
                  </div>
                  <div class="col-md-9" ref={div1}>
                    <MotionInView>
                      <div className={isVisible1 ? "customAnimation" : ""}>
                      </div>
                      <h1
                        className="head-1 gsap-reveal-hero">IntelligenceFx Substratum
                        Series</h1>
                      <h2 className="head-2 gsap-reveal-hero">
                        Advanced forex course that consist of 8 level with 38 modules explained in
                        pdfs and videos with more than 3000 watching hours with full road map to
                        consistency and trading financial market professionally.
                      </h2>
                    </MotionInView>

                  </div>
                </div>
              </MotionInViewCustom>
            </div>
            <div class="col-lg-6">
              <MotionInViewCustom
                variants={{
                  animate: varFade().inLeft.animate,
                  initial: varFade().inLeft.initial
                }}
                exit={varFade().inLeft.exit}
              >
                <div class="row mt-5">
                  <div class="col-md-3">
                    <img src={Private} width="80px" class="img-fluid" alt="private community"/>
                  </div>
                  <div class="col-md-9" ref={div1}>
                    <MotionInView>
                      <div className={isVisible1 ? "customAnimation" : ""}>
                      </div>
                      <h1 class="head-1 gsap-reveal-hero">IntelligenceFx Community</h1>
                      <h2 class="head-2 gsap-reveal-hero">
                        Be a part of a global community of experienced traders who meet to
                        discuss, interact, and trade.
                        Discussion of the daily zoom, as well as the midweek and weekly
                        market breakdowns.
                     </h2>
                    </MotionInView>
                  </div>
                </div>
              </MotionInViewCustom>
            </div>

          </div>

          <div class="row">

            <div class="col-lg-6">
              <MotionInViewCustom
                variants={{
                  animate: varFade().inRight.animate,
                  initial: varFade().inRight.initial
                }}
                exit={varFade().inRight.exit}

              >
                <div class="row mt-5">
                  <div class="col-md-3">
                    <img src={Private} width="80px" class="img-fluid" alt="private community"/>
                  </div>
                  <div class="col-md-9" ref={div2}>
                    <MotionInView>
                      <div className={isVisible2 ? "customAnimation" : ""}>
                      </div>
                      <h1 class="head-1 gsap-reveal-hero">Traders Funding Program</h1>
                      <h2 class="head-2 gsap-reveal-hero">
                        The traders funding program is for IntelligenceFx traders who have
                        been consistency in their trading performance and they are ready for
                        funding
                      </h2>
                    </MotionInView>
                  </div>
                </div>
              </MotionInViewCustom>
            </div>

            <div class="col-lg-6">
              <MotionInViewCustom
                variants={{
                  animate: varFade().inUp.animate,
                  initial: varFade().inUp.initial
                }}
                exit={varFade().inUp.exit}
              >
                <div class="row mt-5">
                  <div class="col-md-3">
                    <img src={Private} width="80px" class="img-fluid" alt="private community"/>
                  </div>
                  <div class="col-md-9" ref={div2}>
                    <MotionInView>
                      <div className={isVisible2 ? "customAnimation" : ""}>
                      </div>
                      <h1 class="head-1 gsap-reveal-hero">Trading Floor</h1>
                      <h2 class="head-2 gsap-reveal-hero">
                        Full access to trading floor for community members
                      </h2>
                    </MotionInView>
                  </div>
                </div>
              </MotionInViewCustom>
            </div>


          </div>
        </div>
      </section>
      {/*  four sections  */}


      <section className="section-five"
               style={{backgroundImage: `url(${Mainlogobg}`, backgroundRepeat: "no-repeat"}}>
        <div class="row mb-5">
          <div class="col"></div>
          <div class="col-md-8 text-center">
            <MotionInViewCustom
              variants={{
                animate: varFade().inLeft.animate,
                initial: varFade().inLeft.initial
              }}
              exit={varFade().inLeft.exit}

            >
              <h1 class="shoulder-1">TRAINING AND MENTORSHIP</h1>
              <h2 class="heading-2">
                One on one training session, access to currently and coming highly
                valuable prepared learning materials and access to powerful and
                interactive members dashboard with daily uploads that will help you to
                gain experience in short period of time as you will learn in the current
                market


              </h2>
            </MotionInViewCustom>


            <MotionInViewCustom
              variants={{
                animate: varFade().inLeftSlow.animate,
                initial: varFade().inLeftSlow.initial
              }}
              exit={varFade().inLeftSlow.exit}
            >
              <div class="row mt-5">
                <div class="col"></div>
                <div class="col-md-8 text-center">
                  <a href="https://app.intelligenceforex.com/pricing"
                     class="btn btn-primary hvr-wobble-top">Become A Member</a>
                </div>
                <div class="col"></div>
              </div>
            </MotionInViewCustom>


          </div>
          <div class="col"></div>
        </div>


        <div class="row">


          <div class="col-md-4 mb-5">
            <MotionInViewCustom
              variants={{
                animate: varFade().inDown.animate,
                initial: varFade().inDown.initial
              }}
              exit={varFade().inDown.exit}

            >
              <div className="card-testimonials box6">
                <img src={Trainingone} class="img-fluid" alt="Training" width=""/>
              </div>
            </MotionInViewCustom>
          </div>

          <div class="col-md-4 mb-5">
            <MotionInViewCustom
              variants={{
                animate: varFade().inUp.animate,
                initial: varFade().inUp.initial
              }}
              exit={varFade().inUp.exit}

            >
              <div className="card-testimonials box6">
                <img src={Trainingtwo} class="img-fluid" alt="Training" width=""/>
              </div>
            </MotionInViewCustom>
          </div>

          <div class="col-md-4 mb-5">
            <MotionInViewCustom
              variants={{
                animate: varFade().inRight.animate,
                initial: varFade().inRight.initial
              }}
              exit={varFade().inRight.exit}

            >
              <div className="card-testimonials box6">
                <img src={Trainingthree} class="img-fluid" alt="Training" width=""/>
              </div>
            </MotionInViewCustom>
          </div>


        </div>
        <div class="light-line"></div>

      </section>
      {/*  three sections  */}


      <section className="section-six"
               style={{backgroundImage: `url(${Mainlogobg}`, backgroundRepeat: "no-repeat"}}>

        <div class="row mb-3">
          <div class="col"></div>
          <div class="col-md-8 text-center">
            <h1 class="shoulder-1">SIGNAL AND SETUP</h1>
          </div>
          <div class="col"></div>
        </div>


        <div class="row mt-5 mb-5">
          <div class="col"></div>
          <div class="col-md-4">
            <div class=""><img src={Signal} class="img-fluid" alt="Signal" width=""/></div>
          </div>
          <div class="col-md-6 mt-3">
            <MotionInViewCustom
              variants={{
                animate: varFade().inUp.animate,
                initial: varFade().inUp.initial
              }}
              exit={varFade().inUp.exit}
            >
              <h1 class="heading-1">High Accuracy Signals and Setups</h1>
              <h2 class="head-2">
                Helps those who cannot participate fulltime in the financial market in
                the financial market analysis and follow up, to access chart analysis,
                signal entry price, stop loss ,take profit, trades updates, market
                outlooks, etc
              </h2>
            </MotionInViewCustom>
            <div class="light-line-small"></div>
            <MotionInViewCustom
              variants={{
                animate: varFade().inUpSlow.animate,
                initial: varFade().inUpSlow.initial
              }}
              exit={varFade().inUpSlow.exit}

            >
              <a href="https://app.intelligenceforex.com/pricing" class="btn btn-primary hvr-wobble-top">Get
                Started</a>
            </MotionInViewCustom>
          </div>
          <div class="col"></div>
        </div>

      </section>
      {/*   signal and setups  */}


      <section className="section-seven">
        <div class="row ">
          <div class="col"></div>
          <div class="col-md-4 text-center mt-4 mb-5">
            <h1 class="heading-1 gsap-reveal-hero">IntelligenceFX Reviews</h1>
            <p class="parag-2 gsap-reveal-hero">
              We have impacted millions of financial market
              traders through our
              community and social media

            </p>

            <div class="row">
              <div  className="col-md-12 text-center">
                <h2 style={{color: '#FECB18'}} className="testimony-1">
                  <span style={{marginRight:'5px'}} >4.1</span>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star-half"></i>
                </h2>
                <p className="parag-2 gsap-reveal-hero">
                  According to trust pilot
                </p>
              </div>

              <div class="col-md-12 text-center">
                <a href="https://www.trustpilot.com/evaluate/intelligenceforex.com" target="_blank" className="btn btn-primary hvr-wobble-top">
                  Leave Your Review
                </a>
              </div>
            </div>
          </div>
          <div class="col"></div>
        </div>
      </section>
      {/*  review area link  */}

      <section className="section-eight"
               style={{backgroundImage: `url(${Mainlogobg}`, backgroundRepeat: "no-repeat"}}>

        <div class="row mb-3">
          <div class="col"></div>
          <div class="col-md-8 text-center">
            <h1 class="shoulder-1">What IntelligenceFX Members Say</h1>
          </div>
          <div class="col"></div>
        </div>


        <div class="row">

          <div class="col-md-4 mb-5">
            <MotionInViewCustom
              variants={{
                animate: varFade().upCustom.animate,
                initial: varFade().upCustom.initial
              }}
            >
              <div className="card-testimonials box6"><img src={Testimonialamri} class="img-fluid"
                                                           alt="Testimonial" width=""/>
              </div>
              <h2 class="testimony-1">Feedback from Amrisali Mkwizu</h2>
              <p class="testimony-2">Founder &amp; CEO</p>
            </MotionInViewCustom>
          </div>

          <div class="col-md-4 mb-5">
            <MotionInViewCustom
              variants={{
                animate: varFade().inUp.animate,
                initial: varFade().inUp.initial
              }}
              exit={varFade().inUp.exit}

            >
              <div className="card-testimonials box6">
                <img src={Testimonialangel} class="img-fluid"
                     alt="Testimonial" width=""/>
              </div>
              <h2 class="testimony-1">Feedback from Angel Majuva</h2>
              <p class="testimony-2">Head of Admnistration</p>
            </MotionInViewCustom>
          </div>

          <div class="col-md-4 mb-5">
            <MotionInViewCustom
              variants={{
                animate: varFade().upCustom2.animate,
                initial: varFade().upCustom2.initial
              }}
            >
              <div className="card-testimonials box6"><img src={Testimonialdastan} class="img-fluid"
                                                           alt="Testimonial" width=""/>
              </div>
              <h2 class="testimony-1">Feedback from Michael Dastan</h2>
              <p class="testimony-2">Head Human Resource Department</p>
            </MotionInViewCustom>
          </div>
        </div>

      </section>
      {/*  video section area  */}

      <section className="section-nine">
        <div class="row mb-5">
          <div class="col-md-4 mb-5">

            <div class="card-testimonials-2">
              <i className="fa fa-quote-left fa-3x"></i>
              <p class="parag-3">I used to gamble my hard earn money on the market due to lack of proper
                skills, emotions where a huge setback in the beginning of my career but IntelligenceFx
                helped me to attain a clear mindset and skillset toward achieving my goals as a trader.
                I now have enormous goals and an intelligible trail to achieving them.</p>
            </div>


            <div class="row mb-5">
              <div class="col"></div>
              <div class="col-md-6"></div>
              <div class="col-md-4">
                <img src={Testimonial1} class="testimony-photo img-fluid" alt="Testimonial" width=""/>
              </div>
              <div class="col"></div>
            </div>


            <div class="row mt-3">
              <div class="col"></div>
              <div class="col-md-8">
                <h2 class="testimony-1">
                  <a href="https://instagram.com/marck_malechela" target="_blank">
                    <i className="fa fa-instagram"></i></a>&nbsp;&nbsp;Joshua Richard Malecela</h2>
                <h2 class="testimony-1-quote">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;IntelligenceFX review</h2>
              </div>
              <div class="col-md-2"></div>
              <div class="col"></div>
            </div>

          </div>
          {/* col md 4 */}


          <div class="col-md-4 mb-5">

            <div class="card-testimonials-2">
              <i class="fa fa-quote-left fa-3x"></i>
              <p class="parag-3">Knowing that my trading is in safe hands
                got me excited. IntelligenceFx made that possible with its excellent trading technique,
                beginning
                this great journey wasn't easy but with their investments was recovered with profit.
                Their confidence, reliability and diligence is undeniable, thank you so much for
                help and will recommend anyone to join.</p>
            </div>


            <div class="row mb-5">
              <div class="col"></div>
              <div class="col-md-6"></div>
              <div class="col-md-4">
                <img src={Testimonial2} class="testimony-photo img-fluid" alt="Testimonial" width=""/>
              </div>
              <div class="col"></div>
            </div>


            <div class="row mt-3">
              <div class="col"></div>
              <div class="col-md-8">
                <h2 class="testimony-1">
                  <a href="https://instagram.com/marck_malechela" target="_blank"><i
                    class="fa fa-instagram"></i></a>&nbsp;&nbsp;Thomson Mwanri</h2>
                <h2 class="testimony-1-quote">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;IntelligenceFX review </h2>
              </div>
              <div class="col-md-2"></div>
              <div class="col"></div>
            </div>

          </div>
          {/* col md 4 */}

          <div class="col-md-4 mb-5">

            <div class="card-testimonials-2">
              <i class="fa fa-quote-left fa-3x"></i>
              <p class="parag-3">
                I was first introduced to the forex markets by a friend about several years ago.
                Immediately, I got hooked and wanted the dream of what it could offer me.
                One day I met Amrisali and decided to learn from him.
                I have never looked back ever since.
                The journey has not been easy, but the knowledge has been worth
                every minute of learning the concepts.</p>
            </div>


            <div class="row mb-5">
              <div class="col"></div>
              <div class="col-md-6"></div>
              <div class="col-md-4">
                <img src={Testimonial3} class="testimony-photo img-fluid" alt="Testimonial" width=""/>
              </div>
              <div class="col"></div>
            </div>


            <div class="row mt-3">
              <div class="col"></div>
              <div class="col-md-8">
                <h2 class="testimony-1">
                  <a href="https://instagram.com/therealdaudinungwana" target="_blank"><i
                    class="fa fa-instagram"></i></a>&nbsp;&nbsp;Daudi Nungwana</h2>
                <h2 class="testimony-1-quote">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;IntelligenceFX review </h2>
              </div>
              <div class="col-md-2"></div>
              <div class="col"></div>
            </div>

          </div>
          {/* col md 4 */}

        </div>
      </section>
      {/* section nine */}


      <section className="section-ten"
               style={{backgroundImage: `url(${Mainlogobg}`, backgroundRepeat: "no-repeat"}}>

        <div class="row mb-3">
          <div class="col"></div>
          <div class="col-md-8 text-center">
            <h1 class="shoulder-1">IntelligenceFX Coaches</h1>
          </div>
          <div class="col"></div>
        </div>


        <div class="row mt-5 mb-5">
          <div class="col"></div>
          <div class="col-md-4">
            <div class=""><img src={Coach} class="img-fluid" width=""/></div>
          </div>
          <div class="col-md-6 mt-3">
            <h1 class="heading-1">Overcoming limiting beliefs in trading</h1>
            <h2 class="head-2">There are many limiting beliefs that can influence your trading.
              If you are able to recognize these beliefs and then address thme, you will be able to
              overcome them.</h2>

            <h2 class="testimony-3"><a href="https://www.instagram.com/amrisaly/" target="blank"><i
              className="fa fa-instagram"></i></a>&nbsp;&nbsp;Amrisali Mkwizu, IntelligenceFX Founder</h2>
            <div class="light-line-small"></div>

          </div>
          <div class="col"></div>
        </div>


      </section>
      {/* section ten */}


    </div>


  );
}

export default Home;